import "bootstrap";
import "simplebar";
import SmoothScroll from "smooth-scroll";

/*

=========================================================
* Volt Pro - Premium Bootstrap 5 Dashboard
=========================================================

* Product Page: https://themesberg.com/product/admin-dashboard/volt-bootstrap-5-dashboard
* Copyright 2021 Themesberg (https://www.themesberg.com)

* Designed and coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal. Contact us if you want to remove it.

*/

("use strict");
const d = document;
d.addEventListener("DOMContentLoaded", function (event) {
    // options
    const breakpoints = {
        sm: 540,
        md: 720,
        lg: 960,
        xl: 1140,
    };

    var sidebar = document.getElementById("sidebarMenu");
    if (sidebar && d.body.clientWidth < breakpoints.lg) {
        sidebar.addEventListener("shown.bs.collapse", function () {
            document.querySelector("body").style.position = "fixed";
        });
        sidebar.addEventListener("d-none.bs.collapse", function () {
            document.querySelector("body").style.position = "relative";
        });
    }

    var iconNotifications = d.querySelector(".notification-bell");
    if (iconNotifications) {
        iconNotifications.addEventListener("shown.bs.dropdown", function () {
            iconNotifications.classList.remove("unread");
        });
    }

    [].slice.call(d.querySelectorAll("[data-background]")).map(function (el) {
        el.style.background = "url(" + el.getAttribute("data-background") + ")";
    });

    [].slice
        .call(d.querySelectorAll("[data-background-lg]"))
        .map(function (el) {
            if (document.body.clientWidth > breakpoints.lg) {
                el.style.background =
                    "url(" + el.getAttribute("data-background-lg") + ")";
            }
        });

    [].slice
        .call(d.querySelectorAll("[data-background-color]"))
        .map(function (el) {
            el.style.background =
                "url(" + el.getAttribute("data-background-color") + ")";
        });

    [].slice.call(d.querySelectorAll("[data-color]")).map(function (el) {
        el.style.color = "url(" + el.getAttribute("data-color") + ")";
    });

    //Tooltips
    var tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 500,
        speedAsDuration: true,
    });

    if (d.querySelector(".current-year")) {
        d.querySelector(".current-year").textContent = new Date().getFullYear();
    }

    const payment_select = document.getElementById("payment_select");
    payment_select.addEventListener("change", function(){
        console.log(payment_select.value);

        [...document.getElementsByClassName("alert-info")].forEach( x => x.classList.add('d-none') )

        if(payment_select.value === "Paypal"){
            document.getElementById("alert_paypal").classList.remove('d-none')
        }

        if(payment_select.value === "Bonifico bancario"){
            document.getElementById("alert_bonifico").classList.remove('d-none')
        }

        if(payment_select.value === "Ritiro in farmacia"){
            document.getElementById("alert_ritiro").classList.remove('d-none')
        }

        if(payment_select.value === "Contrassegno"){
            document.getElementById("alert_contrassegno").classList.remove('d-none')
        }

        if(payment_select.value !== "Ritiro in farmacia"){
            // mostro consegna a cooperativa
            document.getElementById("cooperativa_select").classList.remove('d-none')
        }else{
            document.getElementById("cooperativa_select").classList.add('d-none')
        }
    });

    const delega = document.getElementById("ritiro_cooperativa");
    delega.addEventListener("change", function(){

        if(delega.value === "1"){
            // mostro consegna a cooperativa
            document.getElementById("nome_delega").textContent = document.getElementById('name').value;
            document.getElementById("cognnome_delega").textContent = document.getElementById('surname').value;
            document.getElementById("citta_delega").textContent = document.getElementById('city').value;

            document.getElementById("cooperativa").classList.remove('d-none')
        }else{
            document.getElementById("cooperativa").classList.add('d-none')
        }

    });

    const different_address = document.getElementById("different_address");
    different_address.addEventListener("change", function(event){

        const div_spedizione = document.getElementById("indirizzo-spedizione")
        const inputs = div_spedizione.getElementsByTagName("input");

        for (var i = 0, n = inputs.length; i < n; i = i + 1) {
            inputs[i].required = event.currentTarget.checked;
        }

        if(event.currentTarget.checked){
            div_spedizione.classList.remove('d-none')
        }else{
            div_spedizione.classList.add('d-none')
        }

        document.getElementById("shipping_instructions").required = false;

    });


    const btns_add_file = document.querySelectorAll(".btn-add-file");

    btns_add_file.forEach(btn => {
        btn.addEventListener('click', function handleClick(event) {
          console.log('btn clicked', event);

          if(!btn.dataset.next){
            return
          }

          const next_button = document.getElementsByClassName(btn.dataset.next)[0];

          next_button.classList.remove("d-none")


        });
      });

});
